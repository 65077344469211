import { ModuleType } from '../types'
import { UserType } from '../utils'
import { API_HOST } from '../env';

interface LinkMap {
  [type: string]: LinkInfo[]
}

export interface LinkInfo {
  title: string
  href: string
}

export const authEndpoint = `${API_HOST}${process.env.REACT_APP_AUTH_ENDPOINT}`
export const logoutEndpoint = `${API_HOST}${process.env.REACT_APP_LOGOUT_ENDPOINT}`
export const tokenAuthEndpoint = `${API_HOST}${process.env.REACT_APP_TOKEN_AUTH_ENDPOINT}`

export const adminUrl = '/admin'
export const appendixUrl = '/appendix'
export const contactUsUrl = '/contact-us'
export const dashboardUrl = '/dashboard'
export const factSheetUrl = '/factsheet'
export const factUrl = '/facts'
export const feedbackUrl = '/feedback'
export const homeUrl = '/home'
export const howToUrl = '/howto'
export const linksUrl = '/links'
export const loginUrl = '/login'
export const logoutUrl = '/logout'
export const memberUrl = '/member'
export const moduleUrl = '/module'
export const optoutUrl = '/optout'
export const pageUrl = '/page'
export const registerUrl = '/register'
export const resetUrl = '/reset'
export const surveyInstructionsUrl = '/instructions'
export const surveyUrl = '/survey'
export const verifyUrl = '/verify'
export const aboutUsUrl = '/about-us'
const logoutItem = { title: 'Logout', href: logoutUrl }

export const activeModuleLinks: LinkMap = {
  [ModuleType.Module]: [logoutItem],
}

export const moduleLinks: LinkMap = {
  [ModuleType.Module]: [
    {
      title: 'Appendix',
      href: memberUrl + appendixUrl,
    },
    {
      title: 'Dashboard',
      href: memberUrl + dashboardUrl,
    },
  ],
  [ModuleType.Factsheet]: [
    {
      title: 'Dashboard',
      href: factUrl + dashboardUrl,
    },
  ],
}

const adminUrls = [
  {
    title: 'Admin',
    href: adminUrl + dashboardUrl,
  },
]

if (process.env.REACT_APP_EDIT_MODULES === 'true') {
  adminUrls.push({
    title: 'Edit Modules',
    href: adminUrl + moduleUrl,
  })
}

if (process.env.REACT_APP_EDIT_PAGES === 'true') {
  adminUrls.push({
    title: 'Edit Pages',
    href: adminUrl + pageUrl,
  })
}

if (process.env.REACT_APP_EDIT_SURVEYS === 'true') {
  adminUrls.push({
    title: 'Edit Surveys',
    href: adminUrl + surveyUrl,
  })
}

adminUrls.push(logoutItem)

export const userLinks: LinkMap = {
  [UserType.Participant]: [logoutItem],
  [UserType.Admin]: adminUrls,
}

export const noUserLinks: LinkInfo[] = [
  {
    title: 'Register',
    href: registerUrl,
  },
  {
    title: 'Login',
    href: loginUrl,
  },
]
