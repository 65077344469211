import * as Sentry from '@sentry/browser'
import React, { Component, ErrorInfo, Fragment } from 'react'
import { Box, Button, Container, Link, Typography } from '@material-ui/core'
import { logoutUrl } from '../routes'

interface IErrorState {
  hasError: boolean
  error: Error | null
  eventId: string | undefined
}

/**
 * Basic ErrorBoundary Component
 */
export class ErrorBoundary extends Component<{}, IErrorState> {
  constructor(props: {}) {
    super(props)
    this.state = { hasError: false, error: null, eventId: undefined }
  }

  public componentDidCatch(error: Error, info: ErrorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(info)
      const eventId = Sentry.captureException(error)
      this.setState({ hasError: true, error, eventId })
    })
  }

  public render() {
    if (this.state.hasError) {
      return (
        <Container>
          <Box mt={2}>
            <Typography variant="h4" paragraph={true}>
            We are experiencing some technical difficulties with the application
            </Typography>
          </Box>
          {this.state.error !== null && this.state.eventId !== null ? (
            <Fragment>
              <Typography paragraph={true}>
                We are experiencing some technical difficulties with the PIPA Trial’s Partners in Parenting website. We are working on fixing this and hope to be back up and running soon. We apologise for any inconvenience caused. In the meantime, if you need any help with anything, please contact the PIPA Trial team on pipa@warwick.ac.uk.

                Besides, you can use the 'Report Feedback' button to write a short
                description of what you were working on before you encountered
                the issue. Extra details always helps with tracking down errors.
                If this is the first time you've encountered this error it might
                just be a once off. After reporting feedback you can click
                'Refresh' to go back to the page you were previously on. If you
                encounter the same error multiple times this can sometimes be
                fixed by clearing the cache. Click 'Clear Cache' to try that.
              </Typography>
              <Box clone={true} mr={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    Sentry.showReportDialog({ eventId: this.state.eventId })
                  }
                >
                  Report feedback
                </Button>
              </Box>
              <Box clone={true} mr={2}>
                <Button
                  onClick={() => {
                    window.location.reload()
                  }}
                >
                  Refresh
                </Button>
              </Box>
              <Button component={Link} href={logoutUrl}>
                Clear Cache
              </Button>
              <Box mt={2}>
                <Typography>Here is the error that was reported:</Typography>
                <pre>Error: {this.state.error.stack}</pre>
              </Box>
            </Fragment>
          ) : null}
        </Container>
      )
    }

    return this.props.children
  }
}
