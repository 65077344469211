import React, { Fragment } from 'react'
import { ChevronDown } from 'mdi-material-ui'
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Link,
  Typography,
  Box,
} from '@material-ui/core'
import { BasicAudio } from './module'
import { API_HOST } from '../env'

type PropType = 'href' | 'id' | 'title'
interface PropNode {
  Type: PropType
  Value: string
}

interface FeedbackNode {
  Format:
    | 'body'
    | 'paragraph'
    | 'text'
    | 'bold'
    | 'italics'
    | 'heading1'
    | 'heading2'
    | 'heading3'
    | 'unorderedList'
    | 'listItem'
    | 'fragment'
    | 'div'
    | 'link'
  Content: string
  Children?: FeedbackNode[]
  Props?: PropNode[]
}

function findPropValue(
  props: PropNode[],
  propType: PropType,
): string | undefined {
  const prop = props.find((element) => element.Type === propType)
  return prop ? prop.Value : undefined
}

function findChildrenIDS(children: FeedbackNode[]): string[] {
  const ids: string[] = []
  children.forEach((child) => {
    if (child.Props) {
      const idProp = findPropValue(child.Props, 'id')
      if (idProp) {
        ids.push(idProp)
      }
    }
    if (child.Children) {
      const subResults = findChildrenIDS(child.Children)
      if (subResults.length > 0) {
        ids.push(...subResults)
      }
    }
  })
  return ids
}

function generateAudioUrl(ids: string[]): string {
  return `${API_HOST}/file/audio/feedback/${encodeURIComponent(
    btoa(ids.join(',')),
  )}/feedback.mp3`
}

export const FeedbackNode: React.FunctionComponent<FeedbackNode> = (props) => {
  const children = props.Children
    ? props.Children.map((child, index) => (
        <FeedbackNode key={index} {...child} />
      ))
    : null

  switch (props.Format) {
    case 'body':
      return (
        <div>
          {props.Content}
          {children}
        </div>
      )
    case 'paragraph':
      return (
        <Typography paragraph>
          {props.Content}
          {children}
        </Typography>
      )
    case 'text':
      return (
        <Fragment>
          {props.Content}
          {children}
        </Fragment>
      )
    case 'bold':
      return (
        <b>
          {props.Content}
          {children}
        </b>
      )
    case 'italics':
      return (
        <i>
          {props.Content}
          {children}
        </i>
      )
    case 'heading1':
      return (
        <Typography variant="h1" paragraph>
          {props.Content}
          {children}
        </Typography>
      )
    case 'heading2':
      return (
        <Typography variant="h2" paragraph>
          {props.Content}
          {children}
        </Typography>
      )
    case 'heading3':
      return (
        <Typography variant="h3" paragraph>
          {props.Content}
          {children}
        </Typography>
      )
    case 'unorderedList':
      return (
        <Typography component="ul" paragraph>
          {props.Content}
          {children}
        </Typography>
      )
    case 'listItem':
      return (
        <Typography component="li">
          {props.Content}
          {children}
        </Typography>
      )
    case 'fragment':
      return (
        <Fragment>
          {props.Content}
          {children}
        </Fragment>
      )
    case 'div':
      const title = props.Props
        ? findPropValue(props.Props, 'title')
        : undefined
      const id = props.Props ? findPropValue(props.Props, 'id') : undefined

      if (title) {
        const childrenIDS = findChildrenIDS(
          props.Children ? props.Children : [],
        )
        return (
          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ChevronDown />}>
              <Typography>{title}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div>
                {children}
                <BasicAudio
                  src={generateAudioUrl(childrenIDS)}
                  preload="none"
                />
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )
      }

      if (id && (id === 'main_header' || id === 'main_footer')) {
        return (
          <Box mt={2}>
            {children}
            <BasicAudio src={generateAudioUrl([id])} preload="auto" />
          </Box>
        )
      }

      return <div>{children}</div>
    case 'link':
      const linkProp = props.Props
        ? props.Props.find(function (element) {
            return element.Type === 'href'
          })
        : undefined

      return (
        <Link target="_blank" href={linkProp ? linkProp.Value : undefined}>
          {props.Content}
          {children}
        </Link>
      )
    default:
      return (
        <div>
          {props.Content}
          {children}
        </div>
      )
  }
}
