import React from 'react'
import { lazy, mount, route, redirect, compose, withView, map } from 'navi'
import {
  Login,
  Register,
  Optout,
  ResetPassword,
  VerifyAccount,
  Page,
} from '../pages'
import { PageLayout } from '../components'
import {
  adminUrl,
  homeUrl,
  contactUsUrl,
  linksUrl,
  loginUrl,
  memberUrl,
  logoutUrl,
  registerUrl,
  factUrl,
  optoutUrl,
  logoutEndpoint,
  tokenAuthEndpoint,
  resetUrl,
  verifyUrl,
  aboutUsUrl,
} from './urls'
import { getUserInfo, siteName, fetchLogin } from '../utils'
import { Container } from '@material-ui/core'

async function cleanupUser(client) {
  localStorage.clear()
  await fetch(logoutEndpoint, { credentials: 'include', mode: 'cors' })
  await client.resetStore().catch(() => {})
}

export const routes = compose(
  withView(async (request, context) => <PageLayout />),
  mount({
    '/': redirect(homeUrl),
    [homeUrl]: route({
      title: `${siteName}: Home`,
      view: (
        <Container>
          <Page id="91741cef-38d7-4782-85f2-8ed63c464428" />
        </Container>
      ),
    }),
    [contactUsUrl]: route({
      title: `${siteName}: Contact Us`,
      view: (
        <Container>
          <Page id="91741cef-38d7-4782-85f2-8ed63c464427" />
        </Container>
      ),
    }),
    [linksUrl]: route({
      title: `${siteName}: Useful Links`,
      view: (
        <Container>
          <Page id="91741cef-38d7-4782-85f2-8ed63c464429" />
        </Container>
      ),
    }),
    [aboutUsUrl]: route({
      title: `${siteName}: About Us`,
      view: (
        <Container>
          <Page id="ccac9bd6-f97c-404d-84d9-c8b9697fbdcb" />
        </Container>
      ),
    }),
    [optoutUrl]: route({
      title: `${siteName}: Opt Out`,
      view: (
        <Container>
          <Optout />
        </Container>
      ),
    }),
    [resetUrl]: map(async (request, context) => {
      const userQuery = await getUserInfo(context.client).catch(() => {})
      const userID = userQuery?.data?.whoami?.id
      const { token } = request.params

      return route({
        title: `${siteName}: Reset Password`,
        view: (
          <Container>
            <ResetPassword token={token} isLoggedIn={userID} />
          </Container>
        ),
      })
    }),
    [verifyUrl]: map(async (request) => {
      const { token } = request.params

      return route({
        title: `${siteName}: Verify Account`,
        view: <VerifyAccount token={token} />,
      })
    }),
    [logoutUrl]: redirect(async (request, context) => {
      await cleanupUser(context.client)
      return homeUrl
    }),
    [loginUrl]: map(async (request, context) => {
      const { email, token, username } = request.params
      const userQuery = await getUserInfo(context.client).catch(() => {})
      const userID = userQuery?.data?.whoami?.id

      if (username && token) {
        const url = `${tokenAuthEndpoint}`;
        const loginValues = {
          username: username,
          password: undefined,
          token: token,
        };
        
        const result = await fetchLogin(url, loginValues);
        if (result.options.variant === 'success') {
          return redirect(
            request.params.redirect ? request.params.redirect : homeUrl,
          )
        }
      }

      return !userID
        ? route({
            title: `${siteName}: Login`,
            view: (
              <Container>
                <Login
                  email={email}
                  username={username}
                  token={token}
                  redirectUrl={request.params.redirect}
                />
              </Container>
            ),
          })
        : redirect(homeUrl)
    }),
    [registerUrl]: route(async (request, context) => {
      cleanupUser(context.client)
      const { email, code, tno, ex } = request.params

      return {
        title: `${siteName}: Register`,
        view: (
          <Container>
            <Register code={code} email={email} tno={tno} ex={ex} />
          </Container>
        ),
      }
    }),
    [adminUrl]: lazy(() => import('./adminRoutes')),
    [memberUrl]: lazy(() => import('./memberRoutes')),
    [factUrl]: lazy(() => import('./factRoutes')),
  }),
)
