import React from 'react'
import { FunctionComponent } from 'react'
import { Fragment } from 'react'
import { Typography, Grid } from '@material-ui/core'
import { Img, FinishModule, SingleImage } from '.'

export const FinalPage: FunctionComponent = () => (
  <Fragment>
    <Typography variant="h1">Keep in mind</Typography>
    <br />
    <Grid container>
      <Grid item xs={12} sm={8}>
        <Typography paragraph>
          Don't be afraid to try out new ways of parenting. Putting these ideas
          into practice with your teenager takes time. <b>Be patient.</b>
        </Typography>
        <Typography paragraph>
          If you feel you've made some parenting mistakes, don't be too hard on
          yourself. You can always learn from these mistakes.{' '}
          <b>Be kind to yourself.</b>
        </Typography>
        <Typography paragraph>
          If your teenager has already developed depression or clinical anxiety,
          don't feel that it is a failure on your part or blame yourself. Any
          teenager can develop these problems.{' '}
          <b>
            Just by completing this module, you are already taking steps in the
            right direction towards helping your teenager.
          </b>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Img
          src="/static/svg/all/52.svg"
          alt="take care"
        />
      </Grid>
    </Grid>
    <SingleImage
      gridSize={4}
      src="/static/svg/all/52.1.svg"
      alt="Keep in mind image"
    />
    <FinishModule />
  </Fragment>
)
