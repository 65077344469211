import * as Yup from 'yup'
import React, { FunctionComponent } from 'react'
import {
  authEndpoint,
  dashboardUrl,
  factUrl,
  homeUrl,
  memberUrl,
} from '../routes'
import {
  Avatar,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core'
import { fetchLogin, getUserInfo, useToggle } from '../utils'
import { Field, Form, Formik } from 'formik'
import { FormikTextField } from '../components'
import { gql } from 'graphql.macro'
import { LockOutline } from 'mdi-material-ui'
import { Maybe, ModuleType, useSendResetEmailMutation } from '../types'
import { useApolloClient } from '@apollo/react-hooks'
import { useNavigation } from 'react-navi'
import { useSnackbar } from 'notistack'

export const SEND_RESET_EMAIL = gql`
  mutation SendResetEmail($email: String!) {
    resetPasswordEmail(email: $email)
  }
`

interface LoginProps {
  email: Maybe<string>
  redirectUrl: Maybe<string>
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const LoginValidationSchema = Yup.object().shape({
  email: Yup.string().email('Must be valid email').required('Required'),
  password: Yup.string().min(2, 'Too Short').required('Required'),
})

const ResetPasswordValidationSchema = Yup.object().shape({
  email: Yup.string().email('Must be valid email').required('Required'),
})

export const Login: FunctionComponent<LoginProps> = ({
  email,
  redirectUrl,
}) => {
  const classes = useStyles()
  const snackbar = useSnackbar()
  const navigation = useNavigation()
  const client = useApolloClient()
  const [open, toggleOpen] = useToggle(false)
  const [sendResetEmail] = useSendResetEmailMutation()

  const emailPrefilled = email ? true : false
  const InitialFormData = { email: email ? email : '', password: '' }
  const InitialResetFormData = { email: '' }

  async function onLogin({
    email,
    password,
  }: {
    email: string
    password: string
  }) {
    const url = `${authEndpoint}`;

    const loginValues = {
      username: email,
      password: password,
      token: undefined
    };
    const result = await fetchLogin(url, loginValues);
    const variant = result.options?.variant
    snackbar.enqueueSnackbar(result.message, result.options)
    if (variant === 'success') {
      redirectLoggedInUser()
    }
  }

  async function redirectLoggedInUser() {
    await client.reFetchObservableQueries().catch(() => {})
    const userQuery = await getUserInfo(client).catch(() => {})
    let moduleType
    if (userQuery) {
      moduleType = userQuery.data.myParticipation?.moduleType
      navigateToPage(moduleType)
    }
  }

  function navigateToPage(moduleType: Maybe<ModuleType>) {
    if (redirectUrl) {
      navigation.navigate(redirectUrl)
    } else {
      switch (moduleType) {
        case ModuleType.Module:
          navigation.navigate(`${memberUrl}${dashboardUrl}`)
          break
        case ModuleType.Factsheet:
          navigation.navigate(`${factUrl}${dashboardUrl}`)
          break
        default:
          navigation.navigate(homeUrl)
      }
    }
  }

  return (
    <Container maxWidth="sm">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutline />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Formik
          onSubmit={onLogin}
          initialValues={InitialFormData}
          validationSchema={LoginValidationSchema}
        >
          {({ isSubmitting, isValidating }) => (
            <Form className={classes.form}>
              <Field
                as={FormikTextField}
                variant="filled"
                margin="normal"
                required
                fullWidth
                label="Email Address"
                name="email"
                type="email"
                autoComplete="email"
                autoFocus
                disabled={emailPrefilled}
              />
              <Field
                as={FormikTextField}
                variant="filled"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                autoComplete="current-password"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                className={classes.submit}
              >
                {isSubmitting || isValidating ? (
                  <CircularProgress size={24} />
                ) : (
                  'Sign In'
                )}
              </Button>
            </Form>
          )}
        </Formik>
        <Link component="button" onClick={toggleOpen}>
          Forgot Password?
        </Link>
      </div>
      <Dialog open={open} onClose={toggleOpen}>
        <Formik
          onSubmit={async ({ email }) => {
            const response = await sendResetEmail({ variables: { email } })
            if (response && response.data) {
              snackbar.enqueueSnackbar('Reset Email Sent', {
                variant: 'success',
              })
              toggleOpen()
            }
          }}
          initialValues={InitialResetFormData}
          validationSchema={ResetPasswordValidationSchema}
        >
          {({ isSubmitting, isValidating }) => (
            <Form>
              <DialogTitle>Forgot Password?</DialogTitle>
              <DialogContent>
                <Typography paragraph>
                  Enter the email you used to register and we will send you an
                  email with instructions on how to reset your password.
                </Typography>
                <Field
                  as={FormikTextField}
                  variant="filled"
                  margin="normal"
                  required
                  fullWidth
                  label="Email Address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  autoFocus
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={toggleOpen}>Close</Button>
                <Button type="submit" color="primary" disabled={isSubmitting}>
                  {isSubmitting || isValidating ? (
                    <CircularProgress size={24} />
                  ) : (
                    'Send Email'
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </Container>
  )
}
