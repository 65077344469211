import * as Yup from 'yup'
import React, { Fragment, FunctionComponent, useState } from 'react'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Container,
  Link,
  Typography,
} from '@material-ui/core'
import { Field, Form, Formik } from 'formik'
import { FormikTextField } from '../components'
import { gql } from 'graphql.macro'
import { isPast, isValid, parseISO } from 'date-fns'
import { loginUrl } from '../routes'
import { SurveyDataWrapper } from '../components'
import { useNavigation } from 'react-navi'
import { useSnackbar } from 'notistack'
import { useSubmitRegistrationMutation } from '../types'

export const SUBMIT_REGISTRATION = gql`
  mutation submitRegistration($user: RegisterUser!) {
    registerUser(user: $user)
  }
`

const registrationValidationSchema = Yup.object().shape({
  email: Yup.string().email('Must be valid email').required('Required'),
  confirmEmail: Yup.string()
    .oneOf([Yup.ref('email')], 'Emails must match')
    .required('Email confirmation is required'),
  password: Yup.string()
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{10,}$/,
      'Password must contain at least one upper case character, one lower, and one number, with a minimum length of 10',
    )
    .required('Required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .required('Password Confirmation is required'),
})

const fieldSettings = {
  variant: 'filled',
  required: true,
  margin: 'normal',
}

interface RegisterProps {
  code: string
  email: string
  tno: string
  ex: string
}

export const Register: FunctionComponent<RegisterProps> = ({
  code,
  email,
  tno,
  ex,
}) => {
  const snackbar = useSnackbar()
  const navigation = useNavigation()
  const [part1, setPart1] = useState({
    email: '',
    password: '',
  })
  const [part2, setPart2] = useState('')
  const [sendRegistration] = useSubmitRegistrationMutation({
    onCompleted: (data) => {
      console.log(data)
      if (data?.registerUser) {
        snackbar.enqueueSnackbar("You're successfully registered", {
          variant: 'success',
        })
        navigation.navigate(
          `${loginUrl}?email=${encodeURIComponent(part1.email)}`,
        )
      }
    },
    onError: (error) =>
      snackbar.enqueueSnackbar(error.message, { variant: 'error' }),
  })

  const tnoOff = process.env.REACT_APP_TNO_MODE === 'false'
  const singleUserModeOff =
    process.env.REACT_APP_SINGLE_USER_TYPE_MODE === 'false'
  const emailPrefilled = email ? true : false
  const emailString = email ?? ''
  const expired = tnoOff ? false : testExpired(ex)
  const registrationDataSchema = {
    email: emailString,
    confirmEmail: emailString,
    password: '',
    confirmPassword: '',
  }

  function testExpired(expiredString: string) {
    const parsedDate = parseISO(expiredString)
    if (isValid(parsedDate)) {
      return isPast(parsedDate)
    }
    return true
  }

  function savePart1Progress({
    email,
    password,
  }: {
    email: string
    password: string
  }) {
    setPart1({ email, password })
  }

  async function submitRegistration() {
    const part2JSON = JSON.parse(part2)
    const data = { ...part2JSON, tno }
    const registration = { ...part1, data: JSON.stringify(data), code: code }
    sendRegistration({
      variables: { user: registration },
    })
  }

  function resetRegistration() {
    setPart1({ email: '', password: '' })
    setPart2('')
  }

  const part1Form = (
    <Formik
      validationSchema={registrationValidationSchema}
      initialValues={{ ...registrationDataSchema }}
      onSubmit={savePart1Progress}
    >
      {({ isSubmitting, isValidating, handleReset }) => {
        return (
          <Form>
            <CardContent>
              <Box display="flex" flexDirection="column">
                <Field
                  as={FormikTextField}
                  {...fieldSettings}
                  label="Email Address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  autoFocus
                  disabled={emailPrefilled}
                />
                <Field
                  as={FormikTextField}
                  {...fieldSettings}
                  label="Confirm Email"
                  name="confirmEmail"
                  type="email"
                  autoComplete="email"
                  disabled={emailPrefilled}
                />
                <Field
                  as={FormikTextField}
                  {...fieldSettings}
                  name="password"
                  label="Password"
                  type="password"
                  autoComplete="current-password"
                />
                <Field
                  as={FormikTextField}
                  {...fieldSettings}
                  name="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  autoComplete="current-password"
                />
              </Box>
            </CardContent>
            <CardActions>
              <Button onClick={handleReset}>Reset</Button>
              <Button
                type="submit"
                fullWidth
                color="primary"
                variant="contained"
                disabled={isSubmitting || isValidating}
              >
                {isSubmitting || isValidating ? (
                  <CircularProgress size={24} />
                ) : (
                  'Next'
                )}
              </Button>
            </CardActions>
          </Form>
        )
      }}
    </Formik>
  )

  const form = (
    <Card>
      {part1.email === '' && part1.password === '' ? (
        part1Form
      ) : part2 === '' ? (
        <CardContent>
          <SurveyDataWrapper
            surveyName="9350e6ec-6fa8-41dc-b941-7d4758262524"
            setData={setPart2}
          />
        </CardContent>
      ) : (
        <Fragment>
          <CardContent>
            <Typography paragraph>
              You'll be registered with the email: <b>{part1.email}</b>
            </Typography>
            {tnoOff ? (
              <Fragment>
                <Typography>
                  Click <b>submit</b> to finalise your registration and an
                  account verification link will be sent to your email.
                </Typography>
                <Typography paragraph>
                  You'll need to click this link before you can log in and start
                  your program.
                </Typography>
                <Typography color="primary">
                  After you click submit, you will receive an automated email
                  from us. If you do not receive this, please check your 'junk'
                  or 'spam' folder. You can mark our email address as 'safe' to
                  prevent this from happening again.
                </Typography>
              </Fragment>
            ) : (
              <Fragment>
                <Typography paragraph>
                  Click <b>submit</b> to finalise your registration.
                </Typography>
                <Typography color="primary">
                  After you register and complete your parenting survey, you
                  will receive a confirmation email from us. If you do not
                  receive this, please check your ‘junk’ or ‘spam’ folder. You
                  can mark our email address as ‘safe’ to prevent this from
                  happening again.
                </Typography>
              </Fragment>
            )}
          </CardContent>
          <CardActions>
            <Button onClick={resetRegistration}>Restart</Button>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={submitRegistration}
            >
              Submit
            </Button>
          </CardActions>
        </Fragment>
      )}
    </Card>
  )

  return (
    <Container maxWidth="md">
      <Typography variant="h1" paragraph>
        Register
      </Typography>
      <Typography paragraph variant="subtitle2">
        Note: If you have already registered, please click the "login" button on
        the top right of this page to log in to your account.
      </Typography>
      <Box my={2}>
        {expired ? (
          <Fragment>
            <Typography paragraph>
              Sorry, this link has expired. Please get in touch with the
              research team at{' '}
              <Link href="mailto:PIPA@warwick.ac.uk">PIPA@warwick.ac.uk</Link>{' '}
              or on <Link href="tel:+44(0)24 76574316">+44(0)24 76574316</Link>{' '}
              or
              <Link href="tel:+44(0)24 76575078">+44(0)24 76575078</Link> to
              discuss your participation in the PIPA trial.
            </Typography>
            <Typography>Thank you.</Typography>
          </Fragment>
        ) : (!singleUserModeOff || code) && (tnoOff || tno) ? (
          form
        ) : (
          <Typography paragraph>
            In order to register you need to be given a special sign up link.
          </Typography>
        )}
      </Box>
    </Container>
  )
}
